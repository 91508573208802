.fixed_plugin_font {
    right: 150px !important;
}
.fixed_plugin_hide {
    display: none;
}
.fixed_plugin_show {
    display: inline;
}
.dropdown_menu_div {
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
}
.fixed_plugin_cross_icon {
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
}
.settings_popup {
    z-index: 9999 !important; 
}
@media (max-width:991px) {
  .settings_popup {
        right: 0!important;
        top: 140px !important;
    }  
}
@media (max-width:500px) {
 .settings_popup {
        top: 400px !important;
    }  
}