.dialog_content_subUser {
  width: 500px;
  overflow-x: hidden;
}
.form_error {
  font-size: 0.8rem;
  color: #ff0000 !important;
}
.status_dropdown {
  color: #6c757c !important;
  font-size: 16px !important;
  height: 50px !important;
  border-radius: 0.4285rem !important;
  font-family: "Poppins", sans-serif !important;
}
.client_form_content {
  overflow-x: hidden;
}
.view_icon_cross {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cursor_style {
  cursor: pointer;
}
.overflow_hidden {
  overflow-x: hidden;
}
.overflow_hidden::-webkit-scrollbar {
  width: 10px;
}
.overflow_hidden::-webkit-scrollbar-track {
  background-color: #dadadb !important;
  border-radius: 100px;
}
.overflow_hidden::-webkit-scrollbar-thumb {
  background-color: #8d8d8f !important;
  border-radius: 100px;
  max-height: 20px;
}
.disabled_input {
  background-color: #d9d9d9 !important;
}
