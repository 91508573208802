.filter_buttons {
  display: flex;
  justify-content: flex-end;
}
.pagination_style {
  /* color: white ; */
  color: #babcbe !important;
  font-size: 24px !important;
  font-family: "Montserrat", sans-serif !important;
}
.accordion_border {
  border-radius: 20px !important;
  box-shadow: none !important;
}
.table-responsive {
  overflow: auto;
  padding-bottom: 10px;
  display: block;
  width: 100%;
}
.form_error {
  font-size: 0.8rem;
  color: #ff0000 !important;
}
.edit_icon {
  background-color: transparent;
  border: none;
  color: #2b6af5;
  font-size: 1rem;
  transition: transform 200ms;
}
.input_container {
  position: relative;
  width: 100%;
}
.main_button_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 13%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  margin-left: 10px;
  border-radius: 10px;
  background-color: #eeefefb0;
  border: none;
}
.input_cross_container {
  transform: translateY(-10%);
  cursor: pointer;
  color: #666d76;
  font-size: 15px !important;
}
.client_name_cross {
  font-size: 12px;
  margin-right: 10px;
  transform: translateY(-10%);
  cursor: pointer;
}
.client_name_list_div {
  background-color: white;
  z-index: 3;
  position: absolute;
  height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 0;
  padding-left: 6px;
  padding-right: 20px;
  width: 100%;
}
.client_name_list_hide {
  display: none;
}
.client_name_list_show {
  display: block;
}
.search_input_style {
  height: 30px !important;
  width: 100% !important;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  color: black !important;
}
.no_records_style {
  pointer-events: none;
}
.client_name_list {
  font-size: 12px;
  padding-left: 12px;
}
.client_name_list:hover {
  background-color: #2086f8;
  color: white !important;
  cursor: pointer;
}
.client_status_input,
.client_list_input {
  color: #6c757c !important;
  font-size: 16px !important;
  height: 56px !important;
  border-radius: 0.4285rem !important;
  /* border-color: white !important; */
  font-family: "Poppins", sans-serif !important;
}
