.spinner_div {
  left: 50% !important;
  position: absolute;
  text-align: center;
  top: 50%;
}
.form_error {
  font-size: 0.8rem;
  color:  #ff0000 !important;
}
.no_data_font {
  color: black;
  font-size: 20px;
}
.no_items_data {
  display: flex !important;
  justify-content: center !important;
  pointer-events: none;
}
.notification_style {
  white-space: pre-wrap;
}
.pagination_position {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.data_center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: auto !important;
  min-height: calc(100vh - 70px) !important;
  padding-right: 50px !important;
}
.client_status_input {
  color: #727272 !important;
  font-size: 16px !important;
  height: 56px !important;
  border-radius: 0.4285rem !important;
  border-color: #ced0d1 !important;
  font-family: "Poppins", sans-serif !important;
}
.people_name_input {
  border-radius: 0.4285rem !important;
  border-color: #ced0d1 !important;
  font-family: "Poppins", sans-serif !important;
  color: #727272 !important;
  font-size: 16px !important;
  min-height: 56px !important;
}
.textarea_input {
  border-radius: 0.4285rem !important;
  border-color: #ced0d1 !important;
  font-family: "Poppins", sans-serif !important;
  color: #727272 !important;
  font-size: 16px !important;
  padding: 10px 10px !important;
}
.skeleton_notification_height {
  min-height: 103.46px !important;
  height: 103.46px !important;
  width: 100% !important;
}
