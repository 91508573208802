.error_text {
    color:  #ff0000 !important;
    font-size: 0.8rem;
}

.logo-content{
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    margin-bottom: 20px;
}

.logo-content img{
    width: 250px;
}
.password_prepend {
    border-right: 1px solid rgba(29, 37, 59, 0.2) !important;
    border-top-right-radius: 0.4285rem;
    border-bottom-right-radius: 0.4285rem;
}
.password_toggle {
    padding-left: 10px;
}
.content_padding {
    padding-top: 100px;
}