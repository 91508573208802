.iframe_position {
    text-align: center;
    overflow-y: hidden;
    
    display: -webkit-flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.iframe_style {
    border: 0;
    box-shadow: 5px 5px 56px 0px rgba(0,0,0,0.25);
    min-height: calc(100vh - 200px) !important;
}
.iframe_hide {
    display: none;
}
.iframe_show {
    display: inline;
}
.iframe_height {
    min-height: calc(100vh - 200px) !important;
}
