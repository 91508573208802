
.password_toggle {
    padding-left: 10px;
    font-size: 25px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.form_error {
    font-size: 0.8rem;
    color:  #ff0000 !important;
  }
  .dialog_content {
    width: 500px;
    overflow-x: hidden;
  }
  .view_icon_cross {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cursor_style {
    cursor: pointer;
  }
  .show_icon {
    display: none;
  }