.form_error {
  font-size: 0.8rem;
  color: #ff0000 !important;
}
label {
  font-size: 1.2rem !important;
}
.information_icon {
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.image_style {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  padding: 10px !important;
}
.view_icon_cross {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cursor_style {
  cursor: pointer;
}
.progress_text {
  text-align: center !important;
}
.progress_text p {
  color: gray !important;
  font-size: 20px !important;
}
.progress_number_text {
  font-size: 15px !important;
}
.radio_group_content {
  margin-top: 10px !important;
}

.radio_group_content label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 1.43 !important;
  letter-spacing: 0.01071em !important;
  color: #222a42 !important;
}
.radio_group_label {
  padding-left: 10px !important;
}

.app_name_input {
  color: #727272 !important;
  font-size: 16px !important;
  height: 56px !important;
  border-radius: 0.4285rem !important;
  border-color: #ced0d1 !important;
  font-family: "Poppins", sans-serif !important;
}
.pagination_style {
  color: #babcbe !important;
  font-size: 24px !important;
  font-family: "Montserrat", sans-serif !important;
}
.table-responsive {
  overflow: auto;
  padding-bottom: 10px;
  display: block;
  width: 100%;
}
.no_records_title {
  text-align: center;
}
.client_list_input {
  border-radius: 0.4285rem !important;
  border-color: #ced0d1 !important;
  font-family: "Poppins", sans-serif !important;
  color: #727272 !important;
  font-size: 16px !important;
  min-height: 56px !important;
}
.client_type_input {
  color: #727272 !important;
  font-size: 16px !important;
  min-height: 56px !important;
  border-radius: 0.4285rem !important;
  border-color: #ced0d1 !important;
  font-family: "Poppins", sans-serif !important;
}
.no_items_data {
  display: flex !important;
  justify-content: center !important;
  pointer-events: none;
}
.active_color {
  color: #669900 !important;
}
.dns_added_color {
  color: #ff9900 !important;
}
.pending_color {
  color: #e6e600 !important;
}
@media (max-width: 1400px) {
  .app_name_input {
    height: 50px !important;
  }
}
