%card-stats {
  hr {
    margin: 5px 15px;
  }
}

.card-stats {
    margin-top:10px;
    @extend %card-stats;

   .info-icon {
     height: 50px;
     width: 50px;
     border-radius: 50%;
     @include diagonal-gradient($primary,$primary-states);

     i {
       color: $white;
       font-size: 1.7em;
       padding: 14px 13px;

     }

     &.icon-danger {
       @include diagonal-gradient($danger,$danger-states);
     }

     &.icon-warning {
       @include diagonal-gradient($warning,$warning-states);
     }

     &.icon-success {
       @include diagonal-gradient($success,$success-states);
     }

     &.icon-info {
       @include diagonal-gradient($info,$info-states);
     }

     &.icon-primary {
       @include diagonal-gradient($primary,$primary-states);
     }
   }

   .numbers{
    //  text-align: right;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
     .card-title{
       margin-top: 0;
       font-size: 30px;
       font-weight: 700;
       margin: 0;
       line-height: 1.2;
     }
     .card-category{
       margin-bottom: 0;
       color: $dark-gray;
       word-break: break-word;
      max-width: 150px;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
     }
   }

   .card-footer{
     padding: 0;
   }

   hr{
     border-color: lighten($black,5%);
   }

   .stats{
     color: rgba($white, 0.6);
     margin: 10px 15px;
     font-size: 0.9em;
   }

  &.card-white {
    hr{
      border-color: rgba(43, 53, 83, 0.2)
    }
  }
}
@media (max-width:1400px){
  .card-stats .numbers .card-category{font-size: 15px;}
  .card-stats .numbers .card-title{font-size: 28px;}
}
@media (max-width:1280px){
  .card-stats .numbers .card-category{font-size: 13px;}
  .card-stats .numbers .card-title{font-size: 26px;}
}
@include media-breakpoint-down(sm){
  .card-stats [class*="col-"] .statistics::after {
    display: none;
  }
}
@media (max-width:991px){
  .card-stats .numbers .card-category {
    max-width: 170px;
  }
}
@media (max-width:480px){
  .card-stats {margin-top:0px;}
}