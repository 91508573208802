.spinner_div {
  left: 50%;
  position: absolute;
  text-align: center;
}
.option_style {
  color: black !important;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: #fff !important;
}
.form_error {
  font-size: 0.8rem;
  color:  #ff0000 !important;
}
.profilepic {
  position: relative;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  border:none !important;
  overflow: hidden;
  /* background-color: #111 !important; */
}

.profilepic:hover .profilepic__content {
  opacity: 1 !important;
}

.profilepic:hover .profilepic__image {
  opacity: 0.4 !important;
}
.profilepic__image {
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.profilepic__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.profilepic__icon {
  color: white !important;
  padding-bottom: 8px;
  font-size: 30px !important;
}
.account_name_style,.client_id_style,.first_name_style,.last_name_style,.email_style,.phone_number_style,.company_name_style {
  color: white ;
}
.upload_avatar_style {
  display: none;
}
.pointer_show {
  cursor: pointer;
}
.pointer_hide {
  pointer-events: none;
}
.skeleton_avatar {
  height: 124px !important;
  width: 124px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.phone_number_skeleton,.company_name_skeleton,.email_skeleton,.last_name_skeleton,.first_name_skeleton,.clientId_skeleton,.acc_name_skeleton {
  height: 50px !important;
}
.invoice_email_skeleton {
  height:50px !important;
  width: 50px !important;
}

