.order_option {
  color: black !important;
}
.form_error {
  font-size: 0.8rem;
  color: #ff0000 !important;
}
.table-responsive {
  overflow: auto;
  display: block;
  width: 100%;
}
.spinner_div {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
}
.pie_chart_position {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.chart_size {
  width: 300px !important;
  height: 300px !important;
}
.pie_chart_div {
  margin-top: 10px;
}
.card_head_customer {
  font-size: 15px !important;
}
.card_body_customer {
  color: white;
  font-weight: 300;
}
.search_filter {
  display: flex;
  justify-content: flex-end;
}
.client_status_option {
  color: black !important;
}
.search_btn {
  font-size: 15px;
}
.successful_rebill_icon,
.cancel_icon,
.refunds_icon,
.failed_rebill_icon,
.attempted_rebill_icon,
.successfull_rebill_per_icon {
  font-size: 30px;
  color: white;
}
.trial_sales_icon {
  color: white;
}
.admin_board {
  width: 70rem;
}
.skeleton_header {
  margin-left: auto;
  margin-right: auto;
  top: 0;
}
.customer_name {
  text-align: center;
}
.client_status_input,
.client_list_input,
.timeframe_input {
  color: #6c757c !important;
  font-size: 16px !important;
  height: 56px !important;
  border-radius: 0.4285rem !important;
  /* border-color: white !important; */
  font-family: "Poppins", sans-serif !important;
}
.no_data_bg_customer {
  background-color: #fff !important;
}
.no_data_bg_customer::after {
  background-color: rgba(102, 102, 102, 0.3) !important;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 9;
}
.no_data_bg_customer::before {
  position: absolute;
  content: "No data available*";
  left: 0;
  right: 0;
  top: 50%;
  margin: auto;
  font-weight: bold;
  font-size: 20px;
  font-family: sans-serif;
  color: black;
  text-align: center;
  z-index: 99;
  transform: translateY(-50%);
}
.blur_data {
  filter: blur(4px);
}
.card_no_data {
  padding: 10px;
}
.footer_color {
  color: black;
}
.no_data_title {
  font-size: 20px !important;
}
.active_no_data_font {
  font-weight: bold;
  font-size: 20px;
  font-family: sans-serif;
}
.blur_background {
  position: relative;
}
.no_data_bg {
  background-color: rgba(102, 102, 102, 0.3) !important;
}
.no_blur {
  filter: blur(3px);
  /* position: absolute; */
}
.no_records_title {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #6c757c !important;
  font-size: 30px;
}
.blur_font {
  filter: blur(0);
  position: absolute;
  color: black;
  font-size: 20px;
  font-weight: bold;
}
.footer_position {
  display: flex;
  justify-content: center;
}
.spinner_div_chart {
  left: 50%;
  text-align: center;
  margin-bottom: 10px !important;
}
.skeleton_doghnut_chart {
  height: 208.667px !important;
  width: 208.667px !important;
}
.skeleton_activeInactval {
  min-width: 150px !important;
  display: block !important;
  padding: 15px 10px !important;
  margin-top: 15px !important;
  border-radius: 10px !important;
  font-size: 42px !important;
  min-height: 72px !important;
}
.trial_sales_skeleton,
.successful_rebill_skeleton,
.failed_rebill_skeleton,
.attempted_rebill_skeleton {
  border-radius: 20px !important;
  overflow: hidden !important;
  margin-bottom: 30px !important;
  min-height: 246px !important;
}
.metrics_table_background > tr:nth-child(odd) {
  background-color: #e6e6e6 !important;
}
.metrics_table_background > tr {
  background-color: #f2f2f2 !important;
}
.dashboard_skeleton_position {
  margin-left: auto;
}
.value_color {
  color: #1e86f5 !important;
}
.accordion_border {
  border-radius: 20px !important;
  box-shadow: none !important;
}
.metrics_form {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.table_metrics {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.metrics_form_no_table {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.metrics_heading {
  font-weight: bold !important;
}
.input_container {
  position: relative;
  width: 100%;
}
.main_button_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 13%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  margin-left: 10px;
  border-radius: 10px;
  background-color: #eeefefb0;
  border: none;
}
.client_name_cross {
  font-size: 12px;
  margin-right: 10px;
  transform: translateY(-10%);
  cursor: pointer;
}
.input_cross_container {
  transform: translateY(-10%);
  cursor: pointer;
  color: #666d76;
  font-size: 15px !important;
}
.client_name_list_div {
  background-color: white;
  z-index: 3;
  position: absolute;
  height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 0;
  padding-left: 6px;
  padding-right: 20px;
  width: 100%;
}
.client_name_list_hide {
  display: none;
}
.client_name_list_show {
  display: block;
}
.search_input_style {
  height: 30px !important;
  width: 100% !important;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px !important;
  margin-right: 10px !important;
  color: black !important;
}
.no_records_style {
  pointer-events: none;
}
.client_name_list {
  font-size: 12px;
  padding-left: 12px;
}
.client_name_list:hover {
  background-color: #2086f8;
  color: white !important;
  cursor: pointer;
}
.client_name_selected {
  color: grey !important;
  font-weight: bold;
}
.client_name_not_selected {
  color: black !important;
  font-weight: normal;
}
.export_link {
  color: white;
  margin-right: 4px;
}
.export_link_pointer {
  pointer-events: none;
  opacity: 0.5;
}
@media (max-width: 1400px) {
  .client_status_input,
  .timeframe_input {
    height: 50px !important;
  }
  .dashboard_skeleton_val_text {
    width: 150px !important;
    height: 56px !important;
  }
}
@media (min-width: 992px) {
  .footer_position {
    display: flex;
    justify-content: center;
    padding-right: 130px;
  }
}
@media (max-width: 1280px) {
  .dashboard_skeleton_val_text {
    width: 164.31px !important;
    height: 52px !important;
  }
}
