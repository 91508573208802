.edit_icon {
  background-color: transparent;
  border: none;
  color: #2b6af5;
  font-size: 1rem;
  transition: transform 200ms;
}
.spinner_div {
  left: 50%;
  position: absolute;
  text-align: center;
}
.edit_icon:hover {
  transform: scale(1.3);
}
.delete_icon {
    background-color: transparent;
  border: none;
  color: red;
  font-size: 1rem;
  transition: transform 200ms;
}
.delete_icon:hover {
transform: scale(1.3);
}
.client_type_option {
  color: black;
}
.filter_buttons {
  display: flex;
  justify-content: flex-end;
}
.pagination_style {
 color: white !important;
}
.no_records_title {
  text-align: center;
}
.table-responsive {
  overflow: auto;
  padding-bottom: 10px;
  display: block;
  width: 100%;
}
.icon_alignment {
  display: flex;
  justify-content: center;
}
.client_status_input,.client_type_input {
  color: #727272 !important;
  font-size: 16px !important;
  height: 56px !important;
  border-radius: 0.4285rem !important;
  border-color: #ced0d1 !important;
  font-family: "Poppins", sans-serif !important;
}
.pagination_style {
  color: #babcbe!important;
  font-size: 24px!important;
  font-family: 'Montserrat', sans-serif !important;
}
@media (max-width:1400px){
  .client_status_input,.client_type_input{height: 50px !important;}
}