.react-datepicker__navigation-icon {
  top: 5px !important;
}
/* font-family: 'Montserrat', sans-serif; */
html body {
  font-family: "Montserrat", sans-serif;
}
.navbar {
  box-shadow: 0 0 5px rgb(0, 0, 0, 0.5);
}
body .navbar-collapse {
  flex-grow: initial;
}
.sidebar:before {
  display: none;
}
.main-logo-holder {
  flex: 1;
}
.main-logo {
  width: 200px;
  margin: 0 auto;
}
.dashboard-page h1 {
  margin-bottom: 20px;
}
.dashboard-page h3 {
  margin-bottom: 10px;
}
.main-title {
  font-size: 33px;
}
.sub-title {
  font-size: 23px;
}
.actInactCustomer {
  margin: 20px 0;
}
.actInactCustomer b {
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
}
.activeCustomer b,
.activeCustomer2 b {
  background: #a060fb;
}
.inactiveCustomer b,
.inactiveCustomer2 b {
  background: #d8bdfd;
}
.actInactCustomer span {
  padding-left: 20px;
  width: 160px;
  font-size: 16px;
  color: #000;
}
.circle-chart {
  position: relative;
}
.circle-chart canvas:before {
  content: "";
}
.circle-chart-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 52%;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  font-family: "Poppins", sans-serif;
}
.circle-chart-text span {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #999;
}
.actInactCustomer2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  padding: 15px 20px;
}
.actInactCustomer2 span {
  display: block;
  font-size: 24px;
  font-weight: 700;
  color: #999;
}
.actInactCustomer2 b {
  min-width: 150px;
  color: #fff;
  display: block;
  padding: 15px 10px;
  margin-top: 15px;
  border-radius: 10px;
  text-align: center;
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 1px;
}
.actInactive-holder {
  max-width: 440px;
  margin: 0 auto;
}
.chart_success {
  height: 200px;
}
.commonForm {
  margin: 0 0 30px;
}
.commonForm label,
p.select-title,
h1.select-title {
  font-size: 24px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.commonForm .form-control,
.btn-set button {
  height: 56px;
  border-color: #ced0d1;
}
.commonForm .input-group-prepend .input-group-text,
.commonForm .input-group-append .input-group-text {
  border-color: #ced0d1;
}
.commonForm .form-control {
  font-size: 16px;
}
.commonForm .form-control:focus {
  border-color: #ced0d1 !important;
}
.btn-set button {
  font-size: 20px;
}
.new-icon {
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  line-height: 80px;
}
.new-icon svg,
.new-icon i {
  font-size: 40px;
  line-height: 2;
}
.AdminDashboard_search_filter__Pgkn .btn {
  font-size: 20px;
}
.card-chart.customColor5 {
  height: 100%;
}
.card-chart.customColor5 .card-body {
  display: flex;
  align-items: center;
}
body .btn {
  padding-left: 15px;
  padding-right: 15px;
}
.btn-set .btn {
  min-width: 160px;
  white-space: nowrap;
}
.card-stats .card-body {
  padding-left: 0;
  padding-right: 0;
}
.rounded-2,
.card.rounded-2 {
  border-radius: 20px;
}
.card.superAdmin-card .table .td-actions .btn,
.card.superAdmin-card .table tr td p.title {
  padding-top: 0;
  padding-bottom: 0;
}
select.form-control {
  appearance: none;
  -webkit-appearance: none;
  background-image: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.85rem auto;
}
/* Custom scrollbar */
body .infinite-scroll-component::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}
/* Track */
body .infinite-scroll-component::-webkit-scrollbar-track {
  background: #c4daf0;
  border-radius: 20px;
}
/* Handle */
body .infinite-scroll-component::-webkit-scrollbar-thumb {
  background: #1e86f5;
  border-radius: 20px;
}
/* Handle on hover */
body .infinite-scroll-component::-webkit-scrollbar-thumb:hover {
  background: #1e86f5;
}
/* End Custom scrollbar */
.selectBox .MuiPaper-root,
.selectBox .MuiPaper-root:first-of-type {
  border-radius: 20px;
}
.selectBox .css-avceq3-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
}
.selectBox .css-avceq3-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}
body .card label,
.labelText {
  font-size: 1.2rem;
}
.pagination-holder .MuiToolbar-root,
.pagination-holder p {
  font-size: 22px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.chevron-bg {
  background: #f5f5f5;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 30px;
}
.author p.description {
  font-weight: 600;
  font-size: 18px;
}
body .popupForm .card label {
  font-size: 14px;
}
.commonForm fieldset {
  border: none;
}
.MuiInputBase-root {
  border: 1px solid #cad1d7 !important;
}
.popupForm-holder {
  max-width: 100%;
}
.panel,
.panel-group .panel-heading + .panel-collapse > .panel-body {
  border: none;
  box-shadow: none;
}

.pie-chart-card-skeleton {
  height: 100% !important;
  border-radius: 20px !important;
  overflow: hidden !important;
}
.card-skeleton {
  background: #faf9f9 !important;
  height: 100% !important;
}
.MuiAccordion-root::before {
  background-color: #eeefef !important;
}
.table-bordered {
  border: 2px solid white !important;
}
.table-bordered > tbody > tr > td {
  border: 2px solid white !important;
}
@media (max-width: 1400px) {
  .commonForm .form-control,
  .btn-set button {
    height: 50px;
  }
  .new-icon {
    width: 70px;
    min-width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .new-icon svg,
  .new-icon i {
    font-size: 34px;
  }
}
@media (max-width: 1199px) {
  .main-title {
    font-size: 28px;
  }
  .sub-title {
    font-size: 20px;
  }
  .circle-chart-text {
    font-size: 30px;
  }
  .actInactCustomer2 {
    font-size: 28px;
  }
  .actInactCustomer2 span {
    font-size: 20px;
  }
  .actInactCustomer2 b {
    font-size: 30px;
  }
  .commonForm label,
  p.select-title,
  h1.select-title {
    font-size: 20px;
  }
  .btn-set .btn {
    min-width: 140px;
  }
  .btn-set button {
    font-size: 18px;
  }
  .new-icon {
    width: 60px;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .new-icon svg,
  .new-icon i {
    font-size: 30px;
  }
  .card-stats .numbers {
    padding-left: 15px;
  }
  .card-stats .numbers .card-title {
    font-size: 22px;
  }
  body .card label,
  .labelText {
    font-size: 1.1rem;
  }
  .btn-set button {
    font-size: 20px;
  }
  .pagination-holder .MuiToolbar-root,
  .pagination-holder p {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  body .card label,
  .labelText {
    font-size: 1rem;
  }
}
@media (max-width: 575px) {
  .circle-chart-text {
    font-size: 24px;
  }
  .circle-chart-text span {
    font-size: 14px;
  }
  .actInactCustomer2 {
    font-size: 24px;
  }
  .actInactCustomer2 span {
    font-size: 18px;
  }
  .commonForm label,
  p.select-title,
  h1.select-title {
    font-size: 18px;
  }
  body .display-4 {
    font-size: 1.4rem;
  }
  .btn-set button {
    font-size: 18px;
  }
  .pagination-holder .MuiToolbar-root,
  .pagination-holder p {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .actInactCustomer b {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
  .actInactCustomer span {
    padding-left: 13px;
  }
  .actInactCustomer2 {
    padding: 15px 10px;
  }
  .actInactCustomer2 b {
    font-size: 24px;
    min-width: 130px;
  }
  .commonForm label,
  p.select-title,
  h1.select-title {
    font-size: 16px;
  }
  body .display-4 {
    font-size: 1.2rem;
  }
  .btn-set button {
    font-size: 16px;
  }
  .pagination-holder .MuiToolbar-root,
  .pagination-holder p {
    font-size: 16px;
  }
  .btn-set .btn {
    min-width: 130px;
  }
}
@media (max-width: 359px) {
  .btn-set .btn {
    min-width: auto;
  }
}
