.coming_soon_body {
    text-align: center !important;
    min-height: calc(100vh - 90px) !important;
    display: -webkit-flex !important;
    flex-flow: column !important;
    justify-content: center !important;
    align-items: center !important;
}
.coming_soon_head {
    font-size: 50px;
    font-weight: 700;
    color: white !important;
    margin: 0 0 20px;
}
.coming_soon_desc {
    color: white !important;
}
.coming_soon_div {
    color: red !important;
    width: 100px;
    border-bottom: 1px solid black !important;
    margin: 15px auto 50px;
}