.form_error {
  font-size: 0.8rem;
  color: #ff0000 !important;
}
.client_form_content {
  overflow-x: hidden;
}
.dialog_content {
  width: 500px;
  overflow-x: hidden;
}
.cursor_style {
  cursor: pointer;
}
.view_icon_cross {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
