.btn_error {
  background-color: #dc3545 !important;
  color: white;
  border: none;
  border-width: 2px;
  position: relative;
  overflow: hidden;
  margin: 4px 1px;
  border-radius: 0.4285rem;
  font-weight: 600;
  padding: 11px 40px;
  font-size: 0.875rem;
  line-height: 1.35em;
}
.btn_success {
  background-color: #1d8cf8 !important;
  color: white;
  border: none;
  border-width: 2px;
  position: relative;
  overflow: hidden;
  margin: 4px 1px;
  border-radius: 0.4285rem;
  font-weight: 600;
  padding: 11px 40px;
  font-size: 0.875rem;
  line-height: 1.35em;
}
