/* .search_button {
  margin-bottom: 1.5rem;
} */
.status_dropdown {
  background-color: #27293d !important;
}
.accordion_exapnd {
  /* color: white !important; */
}
.customer_status_select,
.order_type_select,
.order_status_select,
.date_select {
  color: black !important;
}
/* .customer_status_select,
.order_type_select,
.order_status_select,
.date_select option:h {
  color: white !important;
}  */

.edit_icon {
  border: none;
  background-color: #2b6af5 !important;
  font-size: 0.8rem;
  transition: transform 200ms ease-out;
}
.edit_icon:hover {
  transform: scale(1.5);
}

.spinner_div {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
}
.form_label {
  color: #fff;
}
.table_row:hover {
  cursor: pointer;
  opacity: 0.7;
}
.table_row td {
  border-bottom: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.client_name_list {
  font-size: 12px;
  padding-left: 12px;
}
.client_name_list:hover {
  background-color: #2086f8;
  color: white !important;
  cursor: pointer;
}
.client_name_selected {
  color: grey !important;
  font-weight: bold;
}
.client_name_not_selected {
  color: black !important;
  font-weight: normal;
}
.input_container {
  position: relative;
  width: 100%;
}
.no_records_style {
  pointer-events: none;
}
.client_name_list_div {
  background-color: white;
  z-index: 3;
  position: absolute;
  height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 0;
  padding-left: 6px;
  padding-right: 20px;
  width: 100%;
}
.client_name_list_hide {
  display: none;
}
.client_name_list_show {
  display: block;
}
.main_button_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 13%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  margin-left: 10px;
  border-radius: 10px;
  background-color: #eeefefb0;
  border: none;
}
.input_cross_container {
  transform: translateY(-10%);
  cursor: pointer;
  color: #666d76;
  font-size: 15px !important;
}
.client_name_cross {
  font-size: 12px;
  margin-right: 10px;
  transform: translateY(-10%);
  cursor: pointer;
}


.search_input_style {
  height: 30px !important;
  width: 100% !important;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color:black !important
}
.button_col {
  display: flex;
  justify-content: flex-end;
}
.export_button {
  margin-right: 4px;
}
.export_link {
  color: white;
  margin-right: 4px;

}
.export_link_pointer {
  pointer-events: none;
}
/* .reset_button {
  margin-right: 12px;
} */
.pagination_style {
  /* color: white ; */
  color: #babcbe!important;
  font-size: 24px!important;
  font-family: 'Montserrat', sans-serif !important;
}

.order_length_style {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #1E1E2C;
  padding: 0;
  color: white;
  font-weight: bold;
  border: none;
}

.arrow_up_display {
  display: none;
}
.collapsed_table {
  border-color: transparent !important;
}
.collpased_body {
  overflow-x: auto;
}
.no_records_order {
  text-align: center;
}
.view_content_customer {
  width: 500px;
}
.view_details_content {
  color: white;
}
.horizontal_line_view {
  background-color: grey;
}
.disable_click {
  pointer-events: none;
}
.table-responsive {
  overflow: auto;
  padding-bottom: 10px;
  display: block;
  width: 100%;
}
.order_background {
  background-color: #20212f !important;
}
.view_icon_cross {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cursor_style {
  cursor: pointer;
}
.overflow_hidden {
  overflow-x: hidden;

}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #dadadb !important;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background-color: #8D8D8F !important;
  border-radius: 100px;
  max-height: 20px;
}
.client_type_option {
  color: black !important;
}
.customer_status_text, .client_status_input,.order_type_input,.order_status_input,.date_type_input {
  color: #6C757C !important;
  font-size: 16px !important;
  height: 56px !important;
  border-radius: 0.4285rem !important;
  border-color: white !important;
  font-family: "Poppins", sans-serif!important;
}
@media (max-width:1400px){
  .customer_status_text, 
  .client_status_input,
  .order_type_input,
  .order_status_input,
  .date_type_input {height: 50px !important;}
}