.btn {
  & + .btn {
    margin-left: 15px;
  }
}
.btn-set a {
  & + .btn {
    margin-left: 11px;
  }
}
.btn-group + .btn-group {
  margin-left: 15px;
}
@media (max-width:480px){
  .btn {
    & + .btn {
      margin-left: 4px;
    }
  }
  .btn-set a {
    & + .btn {
      margin-left: 1px;
    }
  }
}