.nav-link {
    color: $nav-link-color;

    &:hover {
        color: theme-color("primary");
    }
}

// Pills

.nav-pills {

    .nav-link {
    	  padding: $nav-pills-padding-y $nav-pills-padding-x;
        color: $nav-pills-link-color;
        font-weight: 500;
        background-color: $nav-pills-bg;
        transition: $transition-base;

        &:hover {
        	color: $nav-pills-link-hover-color;
        }
    }

    .nav-link.active,
    .show > .nav-link {
        color: $nav-pills-link-active-color;
        background-color: $nav-pills-link-active-bg;
    }


}

@include media-breakpoint-down(sm) {
    .nav-pills:not(.nav-pills-circle) {
        .nav-item {
            padding-right: 0;
        }
    }
}

// Nav pills circle

.nav-pills-circle {
    .nav-link {
        text-align: center;
        height: 60px;
        width: 60px;
        padding: 0;
        line-height: 60px;
        border-radius: 50%;
    }

    .nav-link-icon {
        i, svg {
            font-size: 1rem;
        }
    }
}

// Nav wrapper
.nav-wrapper {
    padding: 1rem 0;
    @include border-top-radius($card-border-radius);

    + .card {
        @include border-top-radius(0);
        @include border-bottom-radius($card-border-radius);
    }
}


@include media-breakpoint-down(sm) {
  .nav-tabs {
    display: inline-block;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    text-align: center;

    .nav-item > .nav-link {
        margin-bottom: 5px;
    }
  }
}

.style-4::-webkit-scrollbar
{
    width: 10px;
    background-color: #121212;
}

.style-4::-webkit-scrollbar-thumb
{
    background-color: #121212;
    border: 2px solid rgba(255, 255, 255, 0.1);
}

.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 50px;
    width: 40px;
    height: 40px;
    // color: black;
    color: #1e86f5;
    cursor: pointer;
    // background-color: #bbb;
    background-color: #fff;
    border-radius: 50%;    
    padding: 0;
    line-height: 12px;
    border: none;
  }

.back-to-top svg{width:100%!important;height:100%!important;}

.dashboard-page+.footer{display:none;}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}


input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
