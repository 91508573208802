.navmenu_hidden {
    display: none;
}
.sidebar_icon_style {
    font-size: 22px;
}
.sidebar_icon_mini_style {
    font-size: 13px;
}
.bawlio_cursor {
    cursor: default !important;
}
.sidebar_overflow {
    overflow-x: hidden !important;
}