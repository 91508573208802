.spinner_div {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
  }
  .filter_buttons {
    display: flex;
    justify-content: flex-end;
  }
  .pagination_style {
    /* color: white !important; */
    color: #babcbe!important;
    font-size: 24px!important;
    font-family: 'Montserrat', sans-serif !important;
   }
   .table_width {
    width: 100%;
   }
   .acitvity_style {
    height: 30px;
    width: 100%;
    /* background-color: #419EF9; */
   }
   .acitivity_style {
    color: white;
   }
   .no_records_title {
    text-align: center;
  }